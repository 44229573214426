import { graphql, navigate } from "gatsby"
import React from "react"
import BlogCard from "../components/BlogLanding/BlogCard"
import Layout from "../components/layout"
import { SEO } from "../components/seo"
import Pagination from "../components/Pagination/Pagination"
import CONSTANT_INFO from "../constant/constant"
import Tabs from "../components/Tabs"
function BlogPage({ data, pageContext, location }) {
    const { currentPage, numPages } = pageContext
    const { posts } = data

    const handleChangePost = (key) => {
        navigate(`/${key}`);
    }
    const context = {
        pageName: "SD | Blogs",
        pageUri: location.href
    }
    return (
        <Layout context={context}>
            <section className="pt-20">
                <div className="container">
                    <div className="title-container mx-auto flex flex-col py-10 text-center">
                        <h2 className="pre-header">
                            Latest insights and thinking
                        </h2>
                        <h1 className="text-3xl font-bold leading-9 text-black sm:text-4xl md:text-5xl md:leading-[68px]">
                            From our blog
                        </h1>
                    </div>
                    <div>
                        <Tabs active="blog" onChange={handleChangePost} tabs={CONSTANT_INFO.blogTabs} />
                    </div>
                    <div>
                        <div className="grid gap-3 md:grid-cols-2 lg:grid-cols-3">
                            {posts?.nodes.map(node => (
                                <BlogCard key={node.id} node={node} />
                            ))}
                        </div>
                    </div>
                    <Pagination currentPage={currentPage} numPages={numPages} />
                </div>
            </section>
        </Layout>
    )
}

export const query = graphql`
    query ($skip: Int!, $limit: Int!) {
        posts: allWpPost (
            limit: $limit
            skip: $skip
            sort: { order: DESC, fields: date }
            filter: {categories: {nodes:{elemMatch:{name:{ne: "Resources"}}}}}
        ) {
            nodes {
                title
                id
                slug
                categories {
                    nodes {
                        id
                        name
                    }
                }
                featuredImage {
                    node {
                        localFile {
                            childImageSharp {
                                gatsbyImageData
                            }
                        }
                        altText
                    }
                }
                excerpt
                date(formatString: "MMMM DD, YYYY")
            }
        }
    }
`

export default BlogPage
export const Head = ({pageContext }) => (
    <SEO
    title={`Latest Insights and Thinking, Page ${pageContext.currentPage} | Saigon Digital`}
    description={`Read about our latest insights and thinking from the Saigon Digital team | Page ${pageContext.currentPage}`}
    />  
)
